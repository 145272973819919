<script lang="ts" setup>
import ONotificationCenter from "~/organizms/ONotificationCenter.vue";

const { t } = useT();

defineEmits<{ (event: "toggleSidebar"): void }>();

const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const { counterHomePromo } = usePromotionsData();
const isGuest = useIsGuest();
const { data: bonusesData } = useBonusesData();
const { tournamentData, tournamentExist } = useTournamentData();
const bannerPromo = computed(() => appInitData.value?.popupPresetPackage);

const isShowWheel = computed(
	() =>
		bonusesData.value?.dailyWheel?.available || bonusesData.value?.dailyWheel?.reasonType !== "time" || isGuest.value
);
const { isActiveStatus } = usePiggy({ open });
const isShowMoneyBox = computed(() => appInitData.value?.moneyBox?.isActive && (isActiveStatus.value || isGuest.value));

const handleLogin = () => {
	open("LazyOModalLogin");

	dispatchGAEvent({
		event: "login",
		location: "header",
		step: "start"
	});
};

const openWheel = () => {
	open("LazyOModalDailyWheel");
};

const handleSignup = () => {
	open("LazyOModalSignup");

	dispatchGAEvent({
		event: "registration",
		location: "header",
		step: "start"
	});
};

const showMenu = ref(true);
const SCROLL_DELAY = 300;
const lastScrollTop = ref(0);
const scrollTimer = ref<ReturnType<typeof setTimeout> | number | null>(null);

const handleScroll = () => {
	if (scrollTimer.value) {
		clearTimeout(scrollTimer.value);
	}

	const scrollTop = window.scrollY;

	if (scrollTop > lastScrollTop.value) {
		showMenu.value = false;
	}

	showMenu.value = false;
	lastScrollTop.value = scrollTop;

	scrollTimer.value = setTimeout(() => {
		showMenu.value = true;
	}, SCROLL_DELAY);
};

onMounted(() => {
	window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener("scroll", handleScroll);
});
</script>
<template>
	<div class="mob-navigation" :class="{ 'mob-navigation__hide': !showMenu }">
		<MWheelMobNavBar v-if="isShowWheel" class="mob-feature-bar" />
		<ODepositStreakMobNavBar v-else-if="!!appInitData?.depositStreak?.isActive" class="mob-feature-bar" />
		<MTournamentsMobNavBar v-else-if="tournamentExist" class="mob-feature-bar" :title="tournamentData?.title || ''" />
		<MPromotionMobNavBar v-else-if="bannerPromo" :offer="bannerPromo" class="mob-feature-bar" />
		<div class="mob-list">
			<AText
				as="div"
				variant="tempe"
				:modifiers="['uppercase', 'medium']"
				class="mob-item mob-item__more text-coro"
				@click="$emit('toggleSidebar')"
			>
				<NuxtIcon name="20/burger" filled />
				{{ t("Menu") }}
			</AText>

			<template v-if="!isGuest">
				<NuxtLink to="/tournaments/" class="mob-item">
					<NuxtIcon name="20/tournaments" filled />
				</NuxtLink>

				<NuxtLink to="/promotions/" class="mob-item">
					<NuxtIcon name="30/promotions" filled />
					<AAnimationPulse color="var(--camaguey)" :width="18">
						<AText as="div" variant="ternopil" :modifiers="['bold']" class="alarm text-cannes">
							{{ counterHomePromo }}
						</AText>
					</AAnimationPulse>
				</NuxtLink>

				<NuxtLink v-if="isShowMoneyBox" to="/piggy-bank/" class="mob-item">
					<NuxtIcon name="30/piggy-bank" filled />
				</NuxtLink>

				<NuxtLink v-else class="mob-item" @click.prevent="openWheel">
					<NuxtIcon name="20/wheel" filled />
				</NuxtLink>

				<ONotificationCenter />
			</template>

			<div v-else class="buttons-auth">
				<AButton variant="secondary" size="md" class="login-btn" @click="handleLogin">
					<AText variant="tempe" :modifiers="['medium']" class="text-tlalnepantla">{{ t("Sign In") }}</AText>
				</AButton>
				<AButton variant="primary" size="md" class="signup-btn" @click="handleSignup">
					<AText variant="tempe" :modifiers="['medium']" class="text-tlalnepantla">{{ t("Sign Up") }}</AText>
				</AButton>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.mob-navigation {
	position: fixed;
	z-index: 24;
	bottom: 0;
	width: 100%;
	height: 54px;
	transition: 0.35s;

	&__hide {
		bottom: -160px;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.buttons-auth {
	display: flex;
	gap: gutter(1);

	button {
		padding: 0 12px;
	}

	.login-btn {
		border-radius: 8px;
	}
}

.mob-list {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--carletonville);
	box-shadow: 0px -10px 17px 0px rgba(0, 0, 0, 0.32);
	border-radius: 12px 12px 0 0;
	padding: gutter(0.75) gutter(2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 20;

	.logo {
		@media (max-width: 360px) {
			display: none;
		}
	}

	&:deep(.notification-center) {
		width: 40px;
		height: 42px;
		margin-right: 0;

		.ring {
			width: 100%;
			height: 100%;

			.nuxt-icon svg {
				margin-bottom: 0.125em;
			}

			.unread-count {
				right: 2px;
			}
		}
	}

	:deep(.icon) {
		font-size: 30px;
	}
}

.mob-feature-bar {
	position: absolute;
	z-index: 19;
	left: 0;
	bottom: calc(100% - 10px);
}

.navigation-games {
	position: absolute;
	z-index: 19;
	left: 0;
	bottom: calc(100% - 10px);
	width: 100%;
	height: 50px;
	padding: 8px 16px 0;
	display: flex;
	align-items: flex-start;
	background: var(--chesapeake);
	border-radius: 12px 12px 0 0;

	&--offer {
		height: 68px;
		flex-direction: column;
		padding: gutter(1.25) gutter(2) 0;
		gap: gutter(0.5);

		.promo {
			position: absolute;
			top: 10px;
			right: 16px;
		}
	}

	&--best {
		background: var(--guaruja);
	}

	&--most {
		background: var(--guliston);
	}

	&:hover {
		cursor: pointer;
	}
}

.anim-wheel {
	right: 16px;
	top: -18px;
	z-index: 1;
}

.mistery-box-question-image {
	position: absolute;
	animation-iteration-count: infinite;
	right: 16px;
	top: -16px;
}

.mistery-box-image {
	position: absolute;
	right: 0;
	top: -10px;

	&.roulette-event {
		top: -28px;
	}
}

.mob-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-weight: 600;
	min-width: 40px;
	position: relative;

	&__more {
		flex-direction: row;
		gap: 8px;
	}

	&:deep(.bold) {
		line-height: 1;
	}

	.nuxt-icon {
		margin-bottom: auto;
	}

	.nuxt-icon,
	.notification-center {
		font-size: 30px;
		text-align: center;
		vertical-align: middle;
		border-radius: 5px;
		margin-bottom: gutter(0.25);
		color: var(--coro);
	}

	.notification-center:deep(.icon) {
		color: var(--coro);
	}

	&.button {
		border: none;
		padding: 0;
	}

	&.router-link-active {
		.nuxt-icon {
			color: var(--chihuahua);
		}

		span.ternopil {
			background-image: var(--ghaziabad);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.alarm {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute !important;
		top: 0;
		right: 2px;
		width: 17px;
		height: 17px;
		border-radius: 50%;
		background-color: var(--coyoacan);
	}
}
</style>
